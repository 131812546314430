<template>
  <div>
    <div class="head">
      <div class="font-title">
        <span class="bigFont">使用摹客，每人每天节省一小时</span>
        <span class="smallFont">截至2019年8月，我们在全球已获得超过150万活跃的专业用户和超过20万个企业团队，分布在90多个国家和地区。</span>
        <span class="smallFont">微软、Oracle 、华为、网易、快手 、光大银行、民生银行 、IBM 、 西门子电子 、 米其林……，越来越多的知名企业选择摹客 。 摹客也成为了行业的常用</span>
        <span class="smallFont">工具和招聘上岗条件 。 清华大学 、香港大学 、同济大学、台湾大学、Harvard（美国哈佛大学）、UCLA（美国加州大学）、Sydney U（澳洲悉尼大</span>
        <span class="smallFont">学）、 HOWEST（德国西佛兰德大学）等200多所高校在使用或学习摹客。</span>
      </div>
      <div class="imgDiv">
        <img style="margin: 40px 0;" src="../../static/image/enterPRiasd.jpg" height="245" width="100%"/>
      </div>
<!--      <div class="font-title" style="margin-bottom: 30px">-->
<!--        <span class="bigFont">现在和未来的社会由软件支撑，软件制造的核心，在于产品设计</span>-->
<!--        <span class="smallFont">在未来，数字化将席卷和重塑所有的行业，没有所谓“传统行业”，唯有“以软件为核心”的公司，才能快速发展。</span>-->
<!--        <span class="smallFont">产品设计能力，将越来越成为每个企业打造产品，实现业绩增长的核心动力。麦肯锡2018年10月首次发布设计指数（MDI）表明：设计指数排名前25%的公</span>-->
<!--        <span class="smallFont">司，其收入和股东总回报(TRS)，比行业基准增长率，高出2倍。</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyProfile"
}
</script>

<style scoped lang="less">
.font-title{
  span{
    display: block;
    text-align: center;
  }
  .smallFont{
    font-size: 14px;
    line-height: 25px;
  }
  .bigFont{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  //margin-bottom: 40px;
}
.imgDiv{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
</style>
